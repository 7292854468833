import React from 'react'
import { graphql } from 'gatsby'
import { GatsbySeo, CourseJsonLd } from 'gatsby-plugin-next-seo'
import Hero from '../components/hero'
import Cta from '../components/common/cta'
import Tags from '../components/tags'
import Accordion from '../components/common/accordion'
import RelatedArticles from '../components/relatedArticles'
import SectionContainer from '../components/layout/sectionContainer'
import * as coursePageStyling from './course.module.scss'

export default function CoursePage({ data, pageContext, location }) {
    const { contentfulCourse } = data

    return (
        <>
            <GatsbySeo
                title={contentfulCourse.title}
                description={contentfulCourse.description.description}
            />
            <CourseJsonLd
                name={contentfulCourse.title}
                providerName="Vinologik"
                providerUrl="https://vinologik.se/"
                description={contentfulCourse.description.description}
            />
            <Hero
                heading={'Vinkurs: ' + contentfulCourse.title}
                body={contentfulCourse.description.description}
                contain={true}
            />
            <SectionContainer
                title={'Kursens innehåll'}
                subtitle={'Du kommer få lära dig om:'}
                introComponent={
                    <React.Fragment>
                        <div className={coursePageStyling.tagsWrapper}>
                            <Tags tags={contentfulCourse.learnings} />
                        </div>
                        <p>
                            Kursen är uppdelad i{' '}
                            {contentfulCourse.classes.length} olika delar som du
                            gör i din egen takt.
                        </p>
                    </React.Fragment>
                }
            >
                <Accordion allowMultipleOpen={true}>
                    {contentfulCourse.classes &&
                        contentfulCourse.classes.map((currentClass, index) => (
                            <div
                                key={currentClass.id}
                                label={currentClass.title}
                                isOpen={true && index === 0}
                                isQuiz={currentClass.quiz}
                                isLesson={!currentClass.quiz}
                                number={index + 1}
                            >
                                <p>{currentClass.subtitle}</p>
                                {currentClass.quiz && (
                                    <p className={coursePageStyling.quiz}>
                                        Quiz - {currentClass.quiz.title}
                                    </p>
                                )}
                                <Cta
                                    to={currentClass.slug}
                                    isLink={true}
                                    text="Starta"
                                />
                            </div>
                        ))}
                </Accordion>
            </SectionContainer>
            <RelatedArticles headline="Inte redo för skolbänken än?" />
        </>
    )
}

export const pageQuery = graphql`
    query ($title: String!) {
        contentfulCourse(title: { eq: $title }) {
            id
            contentful_id
            title
            learnings
            description {
                description
            }
            # image {
            #     title
            #     gatsbyImageData(
            #         width: 1920
            #         layout: FULL_WIDTH
            #         placeholder: BLURRED
            #     )
            # }
            # mobileImage {
            #     gatsbyImageData(
            #         width: 800
            #         layout: FULL_WIDTH
            #         placeholder: BLURRED
            #     )
            # }
            classes {
                id
                title
                subtitle
                slug
                quiz {
                    title
                }
            }
        }
    }
`
